import isomorphicFetch from 'isomorphic-fetch';

import {
  createZapierClient,
  createTransferClient,
} from '@zapier/transfer-client';
import { zapierUrl, transferUrl } from './urls';

export const zapierClient = createZapierClient({
  fetcher: isomorphicFetch,
  baseUrl: zapierUrl(),
});

export const transferClient = createTransferClient({
  fetcher: isomorphicFetch,
  baseUrl: transferUrl(),
});
